import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import BackgroundImageWrapper from "../components/backgroundImage"
import ClientLogos from "../components/clientLogos"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Testimonials from "../components/testimonials"
import Video from "../components/video"

import "../components/quiz"
import Quiz from "../components/quiz"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <BackgroundImageWrapper hero="hero1" position="top" height="461px">
        <div className="absolute inset-0 bg-gray opacity-60" />
        <div className="px-4 py-150px flex justify-center flex-col text-center relative">
          <h1 className="text-4xl font-bold text-white mb-10px">
            Mediation brengt je verder
          </h1>
          <p className="text-lg font-normal text-white">
            Conflicten oplossen door met elkaar in gesprek te gaan
          </p>
          <div className="flex gap-x-10px mt-15px justify-center">
            <Link
              to="/mediation-bij-een-echtscheiding"
              className="border-2 border-white py-10px px-14px text-lg text-white font-medium underline hover:bg-white hover:text-black transition-all ease-out duration-100"
            >
              Echtscheiding
            </Link>
            <Link
              to="/mediation-bij-een-arbeidsconflict"
              className="border-2 border-white py-10px px-14px text-lg text-white font-medium underline hover:bg-white hover:text-black transition-all ease-out duration-100"
            >
              Arbeidsconflict
            </Link>
          </div>
        </div>
      </BackgroundImageWrapper>
      <section className="py-75px px-5 text-center">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary">
            Waarom mediation?
          </h2>
          <p className="text-lg leading-1.625 font-normal text-primary mb-30px">
            Mediation is dé manier om conflicten aan te pakken. De maatschappij
            wordt steeds juridischer waardoor de neiging ontstaat bij problemen
            naar het wetboek te grijpen. Maar vergeet niet dat 95% van de
            conflicten zijn oorsprong vindt in de relatie en de communicatie.
            Een rechter hakt wel een knoop door maar lost zelden een conflict
            op. Dat kan alleen in gesprek.
          </p>

          <Video
            className="h-393px max-w-700px"
            videoSrcURL="https://www.youtube.com/embed/YP6qSoNE5K0"
            videoTitle="Waarom mediation? Door Rianne Hillenaar van Eerstmediation.nl"
          />
        </div>
      </section>

      <section className="py-75px px-5 text-center bg-secondary">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary">
            Wat is Mediation?
          </h2>
          <p className="text-lg leading-1.625 font-normal text-primary mb-30px">
            Mediation is een manier waarop (sluimerende) conflicten kunnen
            worden opgelost. Mediation is een vorm van bemiddeling waarbij de
            mediator optreedt als onafhankelijke en neutrale gespreksleider. De
            mediator probeert door vraagstelling boven tafel te krijgen wat het
            werkelijke probleem tussen partijen is en wat hun belangen zijn. Het
            gaat er niet alleen om wát je wilt maar vooral waarom je iets wilt.
            Mediation is vrijwillig en vertrouwelijk. Vaak zijn er twee tot vijf
            gezamenlijke gesprekken nodig om tot een oplossing te komen.
          </p>

          <Video
            className="h-393px max-w-700px"
            videoSrcURL="https://www.youtube.com/embed/BA_z1yca88Q"
            videoTitle="Wat is Mediation?"
          />
        </div>
      </section>

      <section className="py-75px text-center">
        <div className="max-w-700px mx-auto">
          <StaticImage
            src="../assets/images/eerstmediation-thumbnail-big.png"
            placeholder="blurred"
            className="h-98px w-98px mb-5"
            alt="eerstmediation thumbnail big"
          />
          <h2 className="text-27px mb-5 leading-1.2 font-normal text-primary">
            Eerstmediation.nl
          </h2>
          <p className="text-lg leading-1.625 font-normal text-primary ">
            Eerstmediation.nl is een Mediation & Advocatenkantoor dat is
            gespecialiseerd in mediation bij echtscheidingen en
            arbeidsconflicten in het Land van Cuijk en de regio Nijmegen.
          </p>
          <Link
            to="/over-het-kantoor"
            className="inline-block py-10px px-14px text-lg underline text-link hover:text-primary"
          >
            Meer over het kantoor
          </Link>
        </div>
      </section>

      <section className="py-75px px-5 bg-secondary text-center">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary mb-30px">
            Lidmaatschappen
          </h2>
          <ClientLogos />
        </div>
      </section>

      <section className="py-75px px-5 text-center">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary mb-30px">
            Reacties van klanten
          </h2>
          <Testimonials />
          <Link
            to="/recensies"
            className="inline-block py-10px px-14px text-lg underline text-link hover:text-primary"
          >
            Meer reacties
          </Link>
        </div>
      </section>

      <section className="py-75px px-5 bg-light-green text-center ">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal mb-30px text-white">
            Lees verder hoe Eerstmediation.nl conflicten oplost
          </h2>
          <div className="flex gap-x-2 mt-15px justify-center">
            <Link
              to="/mediation-bij-een-echtscheiding"
              className="border-2 border-white py-10px px-14px text-lg text-white font-medium underline hover:bg-white hover:text-black transition-all ease-out duration-100"
            >
              Echtscheiding
            </Link>
            <Link
              to="/mediation-bij-een-arbeidsconflict"
              className="border-2 border-white py-10px px-14px text-lg text-white font-medium underline hover:bg-white hover:text-black transition-all ease-out duration-100"
            >
              Arbeidsconflict
            </Link>
          </div>
        </div>
      </section>

      <section className="py-75px px-5 text-center">
        <div className="max-w-700px mx-auto">
          <Quiz />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
