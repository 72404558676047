import React, { useState } from "react"

const Quiz = () => {
  const [isStart, setIsStart] = useState(false)
  const [score, setScore] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [currentSuggestion, setCurrentSuggestion] = useState(0)
  const [isFinished, setIsFinished] = useState(false)

  const questions = [
    {
      title: "Ik zie wel mogelijkheden voor een redelijke oplossing",
    },
    {
      title: "Ik heb belang bij een snelle oplossing",
    },
    {
      title: "Ik heb in de toekomst nog vaker met de andere partij te maken",
    },
    {
      title: "Ik wil graag een oplossing op maat waar ik zelf invloed op heb",
    },
    {
      title: "Het gaat mede om communicatiestoringen",
    },
    {
      title: "Ik heb onderhandelingsruimte",
    },
  ]

  const suggestion = [
    {
      title: "Mediation lijkt minder geschikt voor uw probleem",
    },
    {
      title: "Mediation is geschikt voor uw probleem.",
    },
  ]

  const handleStartQuiz = () => {
    setIsStart(!isStart)
  }

  const nextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1)

    if (currentQuestion === questions.length - 1) {
      setIsFinished(true)
      result()
    }
  }

  const handleNee = () => {
    nextQuestion()
  }

  const handleJa = () => {
    setScore(score + 1)
    nextQuestion()
  }

  const result = () => {
    if (score < 3) {
      setCurrentSuggestion(0)
    } else {
      setCurrentSuggestion(1)
    }
  }

  const resetQuestion = () => {
    setIsStart(false)
    setScore(0)
    setCurrentQuestion(0)
    setCurrentSuggestion(0)
    setIsFinished(false)
  }

  return (
    <div>
      <h2 className="text-27px pb-15px leading-1.2 font-normal mb-30px text-primary">
        Mediation test
      </h2>
      {isStart ? (
        <div>
          {isFinished ? (
            <div>
              <p className="text-lg leading-1.625 font-normal text-primary mb-30px">
                {suggestion[currentSuggestion].title}
              </p>
              <button
                className="bg-gray rounded-5px py-15px px-30px text-base leading-1.15 text-white hover:bg-link"
                onClick={() => resetQuestion()}
              >
                Opnieuw
              </button>
            </div>
          ) : (
            <div>
              <p className="text-lg leading-1.625 font-normal text-primary mb-30px">
                {questions[currentQuestion].title}
              </p>
              <div className="flex gap-x-3 justify-center">
                <button
                  className="bg-gray rounded-5px py-15px px-30px text-base leading-1.15 text-white hover:bg-link"
                  onClick={() => handleJa()}
                >
                  Ja
                </button>

                <button
                  className="bg-gray rounded-5px py-15px px-30px text-base leading-1.15 text-white hover:bg-link"
                  onClick={() => handleNee()}
                >
                  Nee
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p className="text-lg leading-1.625 font-normal text-primary mb-30px">
            Ontdek of mediation geschikt is voor uw conflict
          </p>
          <button
            className="bg-gray rounded-5px py-15px px-30px text-base leading-1.15 text-white hover:bg-link"
            onClick={() => handleStartQuiz()}
          >
            start
          </button>
        </div>
      )}
    </div>
  )
}

export default Quiz
