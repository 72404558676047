import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const ClientLogos = () => {
  return (
    <div className="flex flex-col md:grid md:grid-cols-3 md:grid-rows-2 items-center md:justify-center md:gap-x-5 gap-y-45px">
      <Link to="https://mfnregister.nl/" target="_blank">
        <StaticImage src="../assets/images/clients/client-1.png" className="w-125px" alt="mfnregister" />
      </Link>
      <Link to="https://mfam.nu/" target="_blank">
        <StaticImage src="../assets/images/clients/client-2.png" className="w-125px" alt="mfam" />
      </Link>
      <Link to="https://www.advocatenorde.nl/" target="_blank">
        <StaticImage src="../assets/images/clients/client-3.png" className="w-125px" alt="advocatenorde"/>
      </Link>
      <Link to="https://nvvma.nl/" target="_blank">
        <StaticImage src="../assets/images/clients/client-4.png" className="w-125px"  alt="nvvma"/>
      </Link>
      <Link to="https://www.rechtsbijstand.nl/" target="_blank">
        <StaticImage src="../assets/images/clients/client-5.png" className="w-125px" alt="rechtsbijstand" />
      </Link>
      <Link to="http://www.verenigingvoorarbeidsrecht.nl/" target="_blank">
        <StaticImage src="../assets/images/clients/client-6.png" className="w-125px" alt="verenigingvoorarbeidsrecht" />
      </Link>
    </div>
  )
}

export default ClientLogos
